import { ChevronLeft } from '@mui/icons-material';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { darkTheme } from '../app/theme';

import { Button } from './button';

export function Hero({
  small,
  backPath,
  title,
  illustration,
  actions,
}: {
  actions?: ReactNode;
  backPath?: string;
  illustration?: string;
  small?: boolean;
  title: ReactNode;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={darkTheme}>
      <Box alignItems="center" bgcolor="#528BE8" display="flex" flexDirection="column">
        <Box
          display="flex"
          gap={4}
          maxWidth="calc(100% - 64px)"
          minHeight={small ? 200 : 400}
          width={1184}
        >
          <Box
            alignSelf={backPath ? 'flex-start' : 'center'}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            gap={4}
            paddingY={4}
          >
            {backPath && (
              <Box>
                <Button component={Link} size="large" startIcon={<ChevronLeft />} to={backPath}>
                  {t('commons.actions.go_back')}
                </Button>
              </Box>
            )}
            <Typography
              color="primary"
              component="h1"
              fontSize="2.5rem"
              fontWeight={700}
              lineHeight={1.1}
            >
              {title}
            </Typography>
            {actions && (
              <Box columnGap={3} display="flex" flexWrap="wrap" rowGap={1}>
                {actions}
              </Box>
            )}
          </Box>
          {illustration && (
            <Box
              alignSelf="stretch"
              bottom={0}
              display={{ xs: 'none', lg: 'block' }}
              flexShrink={0}
              marginTop={4}
              sx={{
                backgroundImage: `url(${illustration})`,
                backgroundPosition: 'bottom right',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
              width={{ lg: 500 }}
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
