import { IEnvironment, defaultEnvironment } from '@geovelo-frontends/commons';

let _environment: IEnvironment & {
  amplitude: { apiKey: string };
} = {
  ...defaultEnvironment,
  source: 'website',
  apiKey: '0f8c781a-b4b4-4d19-b931-1e82f22e769f',
  amplitude: { apiKey: 'dd23c9883f3a6508101c04846ee088bb' },
};

if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development') {
  _environment = {
    ..._environment,
  };
}

if (process.env.REACT_APP_ENV === 'staging') {
  _environment = {
    ..._environment,
  };
}

if (process.env.REACT_APP_ENV === 'production') {
  _environment = {
    ..._environment,
  };
}

export const environment = _environment;
