import { badgeUrls, useAmplitudeTracker } from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { environment } from '../../environment';

export function AppStoreBadge({ height }: { height: number }): JSX.Element {
  const { t } = useTranslation();
  const { trackEvent } = useAmplitudeTracker();

  return (
    <Box
      alignItems="center"
      component="a"
      display="flex"
      height={height}
      href={`${environment.ios.storeUrls.fr}&pt=413079&mt=8&ct=website`}
      onClick={() => {
        trackEvent('App Store Badge Clicked');
      }}
      overflow="hidden"
      rel="noreferrer"
      target="_blank"
    >
      <img
        alt={t('commons.app_badge_labels.app_store') || ''}
        height={height}
        src={badgeUrls.fr.ios}
      />
    </Box>
  );
}
