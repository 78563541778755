"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommutingTrip = void 0;
class CommutingTrip {
  constructor(id, title, home, work, bicyclePortions) {
    this.id = id;
    this.title = title;
    this.home = home;
    this.work = work;
    this.bicyclePortions = bicyclePortions;
  }
  get bicycleDistanceHomeWork() {
    return this.bicyclePortions.reduce((res, _ref) => {
      let {
        distanceStartEnd
      } = _ref;
      return res + distanceStartEnd;
    }, 0);
  }
  get bicycleDistanceWorkHome() {
    return this.bicyclePortions.reduce((res, _ref2) => {
      let {
        distanceEndStart
      } = _ref2;
      return res + distanceEndStart;
    }, 0);
  }
}
exports.CommutingTrip = CommutingTrip;