import { CompanyIcon, GeolocationIcon } from '@geovelo-frontends/commons';
import { ChipProps, Chip as MuiChip } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Chip({
  bgcolor,
  borderColor,
  color,
  ...props
}: { bgcolor: string; borderColor: string; color: string } & Omit<
  ChipProps,
  'color'
>): JSX.Element {
  return (
    <MuiChip
      {...props}
      size="small"
      sx={{
        bgcolor,
        borderColor,
        borderRadius: 1,
        color,
        gap: 0.5,
        '> svg': { flexShrink: 0 },
        '> span': { lineHeight: '12px', paddingX: 0.5 },
      }}
      variant="outlined"
    />
  );
}

export function CompanyChip(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Chip
      bgcolor="#E4F2DE"
      borderColor="#97D391"
      color="#1B6515"
      icon={
        <CompanyIcon
          sx={{
            '&&': {
              color: '#1B6515',
              fontSize: 12,
              height: 12,
              width: 12,
            },
          }}
        />
      }
      label={t('events.details.type_companies')}
    />
  );
}

export function TerritoryChip(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Chip
      bgcolor="#E0EAF9"
      borderColor="#83ACF1"
      color="#0A429A"
      icon={
        <GeolocationIcon
          sx={{
            '&&': {
              color: '#0A429A',
              fontSize: 12,
              height: 12,
              width: 12,
            },
          }}
        />
      }
      label={t('events.details.type_territories')}
    />
  );
}

export function PastChip(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Chip
      bgcolor="#FAE3EE"
      borderColor="#F390BF"
      color="#DA3A87"
      label={t('events.details.periods_past')}
    />
  );
}
