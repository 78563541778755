"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.childAdministrativeLevels = exports.administrativeLevelsValues = exports.CyclabilityZone = void 0;
var _center = _interopRequireDefault(require("@turf/center"));
var _utils = require("../utils");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const childAdministrativeLevels = exports.childAdministrativeLevels = {
  city: null,
  epci: 'city',
  department: 'epci',
  region: 'department',
  country: 'region',
  world: 'country'
};
const administrativeLevelsValues = exports.administrativeLevelsValues = {
  city: 1,
  epci: 2,
  department: 3,
  region: 4,
  country: 5,
  world: 6
};
class CyclabilityZone {
  constructor(id, administrativeLevel) {
    let ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let countryCode = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    let name = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    let geometry = arguments.length > 5 ? arguments[5] : undefined;
    let stats = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : [];
    let statsBNSC = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : [];
    this.id = id;
    this.administrativeLevel = administrativeLevel;
    this.ref = ref;
    this.countryCode = countryCode;
    this.name = name;
    this.geometry = geometry;
    this.stats = stats;
    this.statsBNSC = statsBNSC;
    if (geometry && geometry.coordinates.length > 0) {
      this.bounds = (0, _utils.toBounds)(geometry);
      this.center = (0, _center.default)(geometry).geometry;
    } else {
      this.bounds = {
        north: 90,
        east: 180,
        south: -90,
        west: -180
      };
      this.center = {
        type: 'Point',
        coordinates: [0, 0]
      };
    }
  }
  get code() {
    const zoneCode = this.name.toLowerCase()
    // .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-').replace(/\'/g, '-');
    switch (this.administrativeLevel) {
      case 'world':
        return 'world';
      case 'country':
        return this.countryCode?.toLowerCase() || '';
      case 'region':
        return zoneCode;
      case 'department':
      case 'epci':
      case 'city':
        return `${zoneCode}_${this.ref}`;
      default:
        return '';
    }
  }
}
exports.CyclabilityZone = CyclabilityZone;