"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShapefileService = void 0;
var _http = require("./http");
class ShapefileService {
  static async requestShapefile(_ref) {
    let {
      partner,
      partnerCode,
      cyclabilityZoneId,
      model,
      epsg,
      type,
      format
    } = _ref;
    try {
      const queryParams = [{
        key: 'model',
        value: model
      }];
      if (cyclabilityZoneId) {
        queryParams.push({
          key: 'cyclability_zone_id',
          value: cyclabilityZoneId
        });
      } else if (partnerCode) {
        queryParams.push({
          key: 'partner',
          value: partnerCode
        });
      } else {
        throw new Error('as least partnerCode or cyclabilityZoneId required');
      }
      if (epsg) {
        queryParams.push({
          key: 'epsg',
          value: epsg
        });
      }
      if (type) {
        queryParams.push({
          key: 'type',
          value: type
        });
      }
      if (format) {
        queryParams.push({
          key: 'file_format',
          value: format
        });
      }
      await _http.HttpService.post('shapefiles', '/shapefiles', queryParams, [], null, partner ? {
        partner: partner.code
      } : {});
      return true;
    } catch (err) {
      console.error('[ShapefileService][requestShapefile]', err);
      throw err;
    }
  }
}
exports.ShapefileService = ShapefileService;