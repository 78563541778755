import { Box } from '@mui/material';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { AppContext } from '../../app/context';

import Footer from './footer';
import Header from './header';

function Layout(): JSX.Element {
  const {
    app: { footerDisplayed },
  } = useContext(AppContext);

  return (
    <>
      <Header />
      <Box display="flex" flexDirection="column" minHeight="100svh" width="100%">
        <Outlet />
      </Box>
      {footerDisplayed && <Footer />}
    </>
  );
}

export default Layout;
