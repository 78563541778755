import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    allVariants: {
      color: '#2C2E30',
    },
    fontFamily: ["'Nunito Sans'", "'Roboto'", 'sans-serif'].join(', '),
  },
  palette: {
    primary: {
      main: '#326ac2',
    },
    secondary: {
      main: '#2ac682',
    },
  },
});

export const darkTheme = createTheme({
  typography: {
    allVariants: {
      color: '#fff',
    },
    fontFamily: ["'Nunito Sans'", "'Roboto'", 'sans-serif'].join(', '),
  },
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#326ac2',
    },
  },
});

export default theme;
