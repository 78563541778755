import { GeoveloInlineWhiteIcon, prodFrontendUrl } from '@geovelo-frontends/commons';
import { Box, Divider, SvgIcon, SvgIconProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  AppStoreBadge,
  FacebookIcon,
  GooglePlayBadge,
  InstagramIcon,
  LinkedinIcon,
  XIcon,
} from '../../components';
import { environment } from '../../environment';

const sections: Array<
  { key: string; links: Array<{ key: string; labelKey: string; url: string }> } & (
    | { title: string }
    | { titleKey: string }
  )
> = [
  {
    key: 'cyclists',
    titleKey: 'events.footer.navigation.cyclists',
    links: [
      {
        key: 'routes',
        labelKey: 'events.footer.navigation.routes',
        url: `${prodFrontendUrl}/fr/routes/`,
      },
      {
        key: 'trips',
        labelKey: 'events.footer.navigation.trips',
        url: `${prodFrontendUrl}/fr/rides-and-trips/`,
      },
      {
        key: 'rides',
        labelKey: 'events.footer.navigation.rides',
        url: `${prodFrontendUrl}/fr/rides-hub/`,
      },
    ],
  },
  {
    key: 'offers',
    titleKey: 'events.footer.navigation.offers',
    links: [
      {
        key: 'territories',
        labelKey: 'events.footer.navigation.territories',
        url: `${prodFrontendUrl}/fr/territories/`,
      },
      {
        key: 'companies',
        labelKey: 'events.footer.navigation.companies',
        url: `${environment.enterpriseShowcaseUrl}`,
      },
      {
        key: 'api',
        labelKey: 'events.footer.navigation.api',
        url: `${prodFrontendUrl}/fr/api/`,
      },
    ],
  },
  {
    key: 'geovelo',
    title: 'Geovelo',
    links: [
      {
        key: 'mission',
        labelKey: 'events.footer.navigation.mission',
        url: `${prodFrontendUrl}/fr/our-mission/`,
      },
      {
        key: 'jobs',
        labelKey: 'events.footer.navigation.jobs',
        url: `${prodFrontendUrl}/fr/join-us/`,
      },
      {
        key: 'blog',
        labelKey: 'events.footer.navigation.blog',
        url: `${prodFrontendUrl}/fr/blog/`,
      },
      {
        key: 'press',
        labelKey: 'events.footer.navigation.press',
        url: `${prodFrontendUrl}/fr/press-area/`,
      },
    ],
  },
  {
    key: 'help',
    titleKey: 'events.footer.navigation.help',
    links: [
      {
        key: 'faq',
        labelKey: 'events.footer.navigation.faq',
        url: `https://faq.geovelo.app/fr/`,
      },
      {
        key: 'contact',
        labelKey: 'events.footer.navigation.contact',
        url: `mailto:contact@geovelo.fr`,
      },
    ],
  },
];

const socialLinks: Array<{
  Icon: ((props: SvgIconProps) => JSX.Element) | typeof SvgIcon;
  key: string;
  title: string;
  url: string;
}> = [
  {
    key: 'linkedin',
    Icon: LinkedinIcon,
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/la-compagnie-des-mobilit-s',
  },
  { key: 'x', Icon: XIcon, title: 'X', url: 'https://twitter.com/geovelofr' },
  {
    key: 'facebook',
    Icon: FacebookIcon,
    title: 'Facebook',
    url: 'https://www.facebook.com/geovelo',
  },
  {
    key: 'instagram',
    Icon: InstagramIcon,
    title: 'Instagram',
    url: 'https://www.instagram.com/geovelofr',
  },
];

function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      component="footer"
      display="flex"
      flexDirection="column"
      flexShrink={0}
      sx={{
        backgroundColor: '#212121',
        color: '#fff',
        zIndex: 1,
      }}
    >
      <Box
        alignSelf="center"
        display="flex"
        maxWidth="100%"
        paddingBottom={5}
        paddingTop={{ xs: 5, lg: 8 }}
        paddingX={{ xs: 5, xl: 10 }}
        width={1384}
      >
        <Box
          alignItems={{ xs: 'center', lg: 'flex-start' }}
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          gap={5}
          width="100%"
        >
          <Box
            alignItems={{ xs: 'center', lg: 'flex-start' }}
            alignSelf={{ xs: 'stretch', lg: 'flex-start' }}
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row' }}
            flexGrow={1}
            gap={{ xs: 5, lg: 10 }}
          >
            <Box>
              <GeoveloInlineWhiteIcon style={{ height: '24px', width: 'auto' }} />
            </Box>
            <Box
              alignSelf={{ xs: 'stretch', lg: 'flex-start' }}
              display="flex"
              flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
              gap={5}
              justifyContent={{ xs: 'space-between', lg: 'flex-start' }}
            >
              {sections.map(({ key, links, ...otherProps }) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  key={key}
                  width={{
                    xs: '100%',
                    sm: 'calc((100% - 40px) / 2)',
                    md: 'auto',
                  }}
                >
                  <Typography
                    color="inherit"
                    fontWeight={700}
                    lineHeight={1.1}
                    sx={{ textDecoration: 'none' }}
                    variant="body2"
                  >
                    {'title' in otherProps ? otherProps.title : t(otherProps.titleKey)}
                  </Typography>
                  {links.map(({ key, labelKey, url }) => (
                    <Typography
                      color="inherit"
                      component="a"
                      href={url}
                      key={key}
                      lineHeight={1.1}
                      sx={{ textDecoration: 'none' }}
                      variant="body2"
                    >
                      {t(labelKey)}
                    </Typography>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            alignItems={{ xs: 'flex-start', sm: 'center', lg: 'flex-start' }}
            alignSelf={{ xs: 'flex-start', sm: 'center', lg: 'flex-start' }}
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row', lg: 'column' }}
            gap={{ xs: 3, sm: 6, lg: 3 }}
            maxWidth="100%"
            width={{ xs: 280, sm: '100%', lg: 280 }}
          >
            <Box display="flex" gap={2}>
              {socialLinks.map(({ key, Icon, title, url }) => (
                <a
                  href={url}
                  key={key}
                  rel="noreferrer"
                  style={{ height: 24, width: 24 }}
                  target="_blank"
                  title={title}
                >
                  <Icon sx={{ color: '#fff' }} />
                </a>
              ))}
            </Box>
            <Box display="flex" flexWrap="wrap" gap={2}>
              <AppStoreBadge height={40} />
              <GooglePlayBadge height={40} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
      <Box
        alignItems="center"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        gap={{ xs: 0, sm: 2 }}
        justifyContent="center"
        paddingX={2}
      >
        <Box alignItems="center" display="flex" height={52}>
          <Typography
            color="inherit"
            component="a"
            href={`${prodFrontendUrl}/fr/privacy-policy/`}
            sx={{ textDecoration: 'none' }}
            variant="body2"
          >
            {t('commons.privacy_policy')}
          </Typography>
        </Box>
        <Box
          borderLeft="thin solid rgba(255, 255, 255, 0.2)"
          display={{ xs: 'none', sm: 'block' }}
          height={16}
        />
        <Box alignItems="center" display="flex" height={52}>
          <Typography
            color="inherit"
            component="a"
            href={`${prodFrontendUrl}/fr/legal-notice/`}
            sx={{ textDecoration: 'none' }}
            variant="body2"
          >
            {t('commons.legal_notice')}
          </Typography>
        </Box>
        <Box
          borderLeft="thin solid rgba(255, 255, 255, 0.2)"
          display={{ xs: 'none', sm: 'block' }}
          height={16}
        />
        <Box alignItems="center" display="flex" height={52}>
          <Typography
            color="inherit"
            component="a"
            href={`${prodFrontendUrl}/fr/eula/`}
            sx={{ textDecoration: 'none' }}
            variant="body2"
          >
            {t('events.navigation.eula')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
