import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './app';

const ele = document.getElementById('root');
if (ele) {
  const root = createRoot(ele);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
