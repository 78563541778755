import { Box, Skeleton, SvgIcon, SvgIconProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

function Data({
  fullWidth,
  Icon,
  value,
}: {
  fullWidth?: boolean;
  Icon: ((props: SvgIconProps) => JSX.Element) | typeof SvgIcon;
  value?: ReactNode;
}): JSX.Element {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexShrink={0}
      gap={1}
      width={fullWidth ? '100%' : undefined}
    >
      {value !== undefined ? (
        <Icon color="primary" sx={{ flexShrink: 0, height: 20, width: 20 }} />
      ) : (
        <Skeleton height={20} variant="circular" width={20} />
      )}
      <Box flexGrow={1}>
        <Typography lineHeight={1} variant="body2">
          {value !== undefined ? value : <Skeleton variant="text" width="90%" />}
        </Typography>
      </Box>
    </Box>
  );
}

export default Data;
