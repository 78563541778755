import { Event, TChallengePeriod } from '@geovelo-frontends/commons';
import { ChevronRight } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from './button';
import { EventCard } from './event-card';

export function EventsList({
  period,
  skeletonsCount,
  events,
  ...props
}: {
  events: Event[] | undefined;
  period: TChallengePeriod;
  skeletonsCount?: number;
} & ({ disableHeader: boolean } | { count: number | undefined; title: ReactNode })): JSX.Element {
  const { t } = useTranslation();

  if (events && events.length === 0) return <></>;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {!('disableHeader' in props) && (
        <Box
          alignItems="center"
          display="flex"
          gap={3}
          justifyContent="space-between"
          minHeight={72}
        >
          <Typography component="h2" fontSize="1.25rem" fontWeight={700}>
            {events ? props.title : <Skeleton variant="text" width={200} />}
          </Typography>
          <Box flexShrink={0}>
            {events ? (
              props.count &&
              props.count >= 3 && (
                <Button
                  color="primary"
                  component={Link}
                  endIcon={<ChevronRight />}
                  size="large"
                  to={`/${period}`}
                  variant="text"
                >
                  {t('events.list.actions.see_all')}
                </Button>
              )
            ) : (
              <Skeleton height={36} variant="rounded" width={100} />
            )}
          </Box>
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" gap={3}>
        {(events
          ? events.map((event) => ({ key: event.id, event }))
          : new Array(skeletonsCount || 3).fill(null).map((_, key) => ({ key, event: undefined }))
        ).map(({ key, event }) => (
          <Box
            key={key}
            width={{ xs: '100%', md: 'calc((100% - 24px) / 2)', lg: 'calc((100% - 48px) / 3)' }}
          >
            <EventCard event={event} period={period} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
