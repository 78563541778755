import { GeoveloInlineWhiteIcon, UserAvatar } from '@geovelo-frontends/commons';
import { Logout } from '@mui/icons-material';
import {
  AppBar,
  Box,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ThemeProvider,
  Toolbar,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { AppContext } from '../../app/context';
import { darkTheme } from '../../app/theme';
import { Button } from '../../components';

function Header(): JSX.Element {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    app: { transparentBackgroundEnabled },
    user: { current: currentUser, signingOut },
    actions: { signOut },
  } = useContext(AppContext);
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <AppBar
          color="default"
          elevation={0}
          position="absolute"
          sx={{ backgroundColor: transparentBackgroundEnabled ? 'transparent' : '#528BE8' }}
        >
          <Toolbar sx={{ justifyContent: 'space-between', gap: 3 }}>
            <Box flexShrink={0}>
              <Link style={{ height: 30 }} to="/">
                <GeoveloInlineWhiteIcon sx={{ height: 30, width: '100%' }} />
              </Link>
            </Box>
            <Box flexShrink={0}>
              {currentUser ? (
                <ButtonBase
                  onClick={({ currentTarget }) => setUserMenuAnchorEl(currentTarget)}
                  sx={{ borderRadius: 5, height: 40, width: 40 }}
                >
                  <UserAvatar size={40} user={currentUser} />
                </ButtonBase>
              ) : (
                <Button
                  component={Link}
                  disabled={currentUser === undefined}
                  size="large"
                  state={{ requestedPath: `${pathname}${search}` }}
                  to="/sign-in"
                  variant="outlined"
                >
                  {t('events.navigation.sign_in')}
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Menu
        anchorEl={userMenuAnchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => setUserMenuAnchorEl(null)}
        open={Boolean(userMenuAnchorEl)}
        slotProps={{ paper: { sx: { marginTop: 1 } } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          dense
          disabled={signingOut}
          onClick={() => {
            setUserMenuAnchorEl(null);
            signOut();
          }}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary={t('commons.actions.sign_out')} />
        </MenuItem>
      </Menu>
    </>
  );
}

export default Header;
